import React from 'react';

import Popup from './Popup.js';

function getLoginBanner(setText) {
  fetch('/api/login_banner')
    .then((response) => response.json())
    .then((response) => {
      if (response.success && response.data.enabled) {
        setText(response.data.text);
      }
    })
    .catch((error) => console.error(error));
}

export default function LoginPopup() {
  const [text, setText] = React.useState(null);
  React.useEffect(() => getLoginBanner(setText), []);
  return <div>{text && <Popup text={text} />}</div>;
}
