import React from 'react';

export default class RequestWithCSRF extends React.Component {
  post(callback) {
    fetch(`${window.globals.versions.api}/auth/new_session/`, {
      method: 'POST',
    })
      .then((response) => response.json())
      .then((response) => {
        this.handleCSRFToken(callback, response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleCSRFToken(callback, result) {
    if (result && result.success) {
      callback(result.csrf);
    } else {
      this.setState({ error: 'other', message: null });
    }
  }
}
